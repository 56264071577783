<template>
  <div id="capa">
    <div class="container">
      <div class="row">
        <div class="col-md-4 pt-5 mb-3 col-sm-12" id="form-container">
          <form
            class="form-control"
            @submit.prevent="criarCategoria"
            id="Categorias"
          >
            <h2
              class="fs-2 text-light text-center fw-bold mb-3"
              id="cabeçario-form"
            >
              Nova Categoria
            </h2>
            <div>
              <label class="fs-5 pt-2 mb-2" for="exampleInputname">Nome</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="bi bi-person"></i
                  ></span>
                </div>
                <input
                  type="text"
                  v-model="categoria.name"
                  class="form-control"
                  style="border-left: none"
                  placeholder="Digite Nome."
                  name="name"
                  required
                />
              </div>
            </div>

            <label class="fs-5 pt-2 mb-2" for="exampleInputname"
              >Palavra-Chave</label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="bi bi-search"></i
                ></span>
              </div>
              <input
                type="text"
                v-model="categoria.keyword"
                class="form-control"
                style="border-left: none"
                placeholder="Digite sua Palavra-chave."
                name="chave"
                required
              />
            </div>
            <div class="fs-5 pt-4 mb-4">
              <div class="d-grid gap-2 fw-bold fs-6 text-light bg-dark">
                <button
                  id="btn-Salvar"
                  class="btn btn-outline-light border-dark fs-4"
                  type="submit"
                  :disabled="btnDisabled"
                >
                  <span
                    v-if="btnDisabled"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span v-else>Salvar</span>
                </button>
              </div>
            </div>
            <br />
          </form>
        </div>
        <div class="col-md-8 col-sm-12">
          <h1 class="title fw-bold text-center pt-4 pb-4" id="titulo-2">
            Lista de Categorias
          </h1>
          <p>Esta tela permite que você cadastre e gerencie categorias para identificar suas transações ao converter suas faturas para CSV.</p>
          <p>Este recurso é exclusivo para <a href="/premium">usuários premium</a>, mas está disponível gratuitamente por tempo limitado como uma demonstração. Aproveite!</p>
          <table class="table table-hover table-bordered text-center">
            <thead class="thead-dark">
              <tr class="h3 text-light bg-primary">
                <th scope="col">Nome</th>
                <th scope="col">Palavra-Chave</th>
                <th scope="col">Excluir</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="categoria in categorias" :key="categoria.uuid">
                <th scope="row" class="h6 pt-4">{{ categoria.name }}</th>
                <td class="h6 pt-4">{{ categoria.keyword }}</td>
                <td class="pt-3 pb-3">
                  <div>
                    <div class="d-grid fw-bold text-light bg-danger">
                      <button
                        id="btn-excluir"
                        class="btn btn-outline-light border-dark"
                        type="submit"
                        @click="deletarCategoria(categoria.uuid)"
                      >
                        <span>Excluir</span>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/http";

export default {
  data() {
    return {
      btnDisabled: false,

      categoria: {
        name: "",
        keyword: "",
      },
      categorias: [],
    };
  },
  methods: {
    criarCategoria() {
      this.btnDisabled = true;
      http
        .post("categories", this.categoria)
        .then(() => {
          this.listarCategorias();
          this.categoria = { name: "", keyword: "" };
          this.btnDisabled = false;
        })
        .catch(() => {
          this.btnDisabled = false;
        });
    },
    deletarCategoria(uuid) {
      console.log("Tentando deletar categoria com uuid:", uuid);
      if (!uuid) {
        console.error("uuid da categoria é inválido:", uuid);
        return;
      }
      http
        .delete(`categories/${uuid}`)

        .then((response) => {
          console.log("Resposta da API ao deletar categoria:", response.data);
          this.categorias = this.categorias.filter(
            (categoria) => categoria.uuid !== uuid
          );
        })
        .catch((error) => {
          console.error("Erro ao deletar categoria:", error);
        });
    },
    listarCategorias() {
      http
        .get("categories")
        .then((response) => (this.categorias = response.data.data))
        .catch((erro) => console.log(erro));
    },
  },
  mounted() {
    this.listarCategorias();
  },
}
</script>

<style>


</style>
