<template>
  <div class="container">
    <div class="row">
      <div class="col-sm"></div>
      <div class="col-md-6 pt-5 mb-5 col-sm-9" id="formLogin">
        <form class="form-control" @submit.prevent="submit" id="formLogin">
          <h2
            class="fs-2 text-light text-center fw-bold mb-3"
            id="cabeçario-form"
          >
            Login
          </h2>
          <div>
            <label class="fs-5 pt-2 mb-2" for="exampleInputEmail ">Email</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="bi bi-envelope"></i
                ></span>
              </div>
              <input
                type="email"
                v-model="usuario.email"
                class="form-control"
                style="border-left: none"
                id="exampleInputEmail"
                aria-describedby="emailHelp"
                placeholder="Escreva o email aqui."
                name="email"
                required
              />
            </div>
          </div>
          <div>
            <label class="fs-5 pt-2 mb-2" for="InputPassword">Senha</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="bi bi-lock"></i></span>
              </div>
              <input
                type="password"
                v-model="usuario.password"
                class="form-control"
                style="border-left: none"
                name="Password"
                placeholder="Digite senha aqui."
                required
              />
            </div>
          </div>
          <div class="container">
            <div class="mb-2 pb-2 d-flex align-items-center">
              <input
                type="checkbox"
                class="form-check-input"
                id="exampleCheck"
              />
              <label
                class="form-check-label form-text text-muted ml-2"
                for="exampleCheck"
                style="margin-left: 10px"
              >
                Lembrar senha
              </label>
            </div>
          </div>
          <div class="fs-5 pt-4 mb-4">
            <div class="d-grid gap-2 fw-bold fs-6 text-light bg-dark">
              <button
                id="btn-converter"
                class="btn btn-outline-light border-dark fs-4"
                type="submit"
                :disabled="btnDisabled"
              >
                <span
                  v-if="btnDisabled"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span v-else>Acessar</span>
              </button>
            </div>
            <router-link class="fs-6" :to="{ name: 'novo.usuario' }">
              Não posssui um cadastro, cadastre-se aqui! </router-link
            ><br />
          </div>
        </form>
      </div>
      <div class="col-sm"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      usuario: {},
      btnDisabled: false,
    };
  },
  methods: {
    submit() {
      this.btnDisabled = true;
      this.$store
        .dispatch("login", this.usuario)
        .then(() => {
          console.log(123);
          this.$router.push({ name: "bank.converter" });
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ops!",
            text: error,
            timer: 4000,
          });
        })
        .finally(() => {
          this.btnDisabled = false;
        });
    },
  },
};
</script>

<style>
.login {
  padding: 10%;
}
.input-group-text {
  background-color: #fff;
  border: 1px solid #ced4da;
}

.bi {
  font-size: 1.1rem;
}
</style>
