<template>
  <div id="capa">
    <section id="section-inicio" class="text-light">
      <div class="container">
        <div class="row justify-content-evenly pt-5 pb-5F">
          <div class="col-md-6 mb-5">
            <h1 class="title fw-bold" id="titulo">Converter PDF para CSV</h1>

            <hr />
            <p class="fs-3 fw-bold">Exclusivo para faturas Santander</p>
            <br />
          </div>
          <div class="col-md-4 mb-3" id="form">
            <form
              id="Historicos"
              class="form-control"
              @submit.prevent="criarHistorico"
            >
              <h2
                class="fs-2 text-light text-center fw-bold mb-3"
                id="cabeçario-form"
              >
                Carregar PDF
              </h2>

              <div>
                <label class="fs-5 pt-2 mb-2" for="Inputaccount"
                  >Tipo de Conta</label
                >
                <div class="input-group">
                  <div class="input-group-prepend"></div>
                  <select
                    name="document_type"
                    class="form-select form-control fs-5"
                    required="true"
                    v-model="document_type"
                  >
                    <option selected value="PJ">Pessoa Jurídica</option>
                    <option value="PF">Pessoa Física</option>
                  </select>
                </div>
              </div>

              <div class="input-group">
                <div class="input-group-prepend"></div>
                <label class="fs-5 pt-2 mb-2">Coloque o Arquivo</label>
              </div>
              <input
                type="file"
                class="form-control-file form-group form-control fs-5 text-center"
                name="file"
              />

              <div>
                <label class="fs-5 pt-2 mb-2" for="InputPassword">Senha</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="bi bi-lock"></i
                    ></span>
                  </div>
                  <input
                    v-model="password"
                    type="password"
                    class="form-control"
                    style="border-left: none"
                    name="Password"
                    placeholder="Digite senha aqui."
                  />
                </div>
              </div>
              <div class="fs-4 pt-4 mb-4">
                <div class="d-grid gap-2 fw-bold fs-6 text-light bg-dark">
                  <button
                    id="btn-converter"
                    class="btn btn-outline-light border-dark fs-4"
                    type="submit"
                    :disabled="btnDisabled"
                  >
                    <span
                      v-if="btnDisabled"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span v-else>Converter</span>
                  </button>
                </div>
              </div>
              <br />
            </form>
          </div>
        </div>
      </div>
    </section>

    <section id="section-info" class="mb-5 mt-5 container">
      <div class="row">
        <div class="col-md-4 text-center">
          <img
            class="img-responsive mb-2"
            src="@/assets/img/RealizePagamento.png"
          />
          <h3><strong>Cadastre suas categorias personalizadas</strong></h3>
          <p class="fs-5">
            Você pode converter seu PDF e já categorizar suas entradas como
            preferir.
          </p>
        </div>
        <div class="col-md-4 text-center">
          <img
            class="img-responsive mb-2"
            src="@/assets/img/CarregueFatura.png"
          />
          <h3><strong>Carregue a Fatura</strong></h3>
          <p class="fs-5">
            Insira a sua fatura e a senha de acesso no formulário e clique em
            Converter
          </p>
        </div>
        <div class="col-md-4 text-center">
          <img class="img-responsive mb-2" src="@/assets/img/BaixeCSV.png" />
          <h3><strong>Baixe o CSV</strong></h3>
          <p class="fs-5">
            Pronto, basta fazer download do seu CSV com todas as transações
            realizadas
          </p>
        </div>
      </div>
    </section>

    <section id="section-ajuda" class="pt-5 pb-5 text-light text-center">
      <div class="container">
        <div class="row justify-content-evenly">
          <div class="col-md-6 col-sm-12">
            <h2><strong>Precisa de ajuda?</strong></h2>
            <h3>
              Caso precise falar conosco, basta chamar no whatsapp que
              responderemos o quanto antes!
            </h3>
          </div>
          <div class="col-md-6 col-sm-12 mt-5">
            <button
              type="button"
              class="btn btn-light btn-outline-primary border-light"
            >
              <a
                id="btn-whatsapp"
                href="https://api.whatsapp.com/send?phone=5511976079408&amp;text=Ola+preciso-de-ajuda-com-o-conversor-santander"
                class="btn solid bdr-4 fw-bold fs-4"
                target="_blank"
                >WhatsAPP (11) 97607-9408</a
              ><img
                id="icon-whats"
                class="img-responsive"
                src="@/assets/img/telefone.png"
              />
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import http from "@/http";

export default {
  data() {
    return {
      btnDisabled: false,
      password: null,
      document_type: "PJ",
    };
  },

  methods: {
    criarHistorico() {
      this.btnDisabled = true;

      const formHTML = new FormData(document.getElementById("Historicos"));
      const data = new FormData();
      data.append("file", formHTML.get("file"));

      const queryParams = {
        code: this.password,
        document_type: this.document_type,
        type: "CREDIT_CARD_SANTANDER",
      };

      http
        .post("conversions", data, { params: queryParams })
        .then((data) => {
          this.$swal({
            icon: "success",
            title: "Sucesso!",
            text: "Seu arquivo CSV foi gerado com sucesso e o download irá começar automaticamente",
            timer: 4000,
          }).then(() => {
            window.open(data.data.path, "_blank");
          });
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ops!",
            text: error.response.data.detail,
            timer: 4000,
          });
        })
        .finally(() => {
          this.btnDisabled = false;
        });
    },
  },
};
</script>

<style>
body {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
h1 {
  font-size: 70px;
}
select .form-control {
  height: calc(2.25rem + 2px);
  padding: 5px;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

#section-inicio {
  background: linear-gradient(
      rgba(183, 163, 73, 0.3) 0%,
      rgba(183, 163, 73, 0.6) 100%
    ),
    url("@/assets/img/fundo.jpg") no-repeat center center;
  background-size: cover;
  margin: 0;
}
#section-ajuda {
  background: #0d6efd;
}
#cabeçario-form {
  background-color: #0d6efd;
  padding: 1.25rem;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0rem;
  text-align: center;
  line-height: 1.3;
  margin-top: -15px;
  margin-left: -12px;
  margin-right: -12px;
}
#btn-converter {
  padding: 15px 32px;
}
#tel {
  margin: 0.25rem 1rem;
}
#icon-whats {
  max-width: 80px;
  max-height: 80px;
}

#btn-whatsapp {
  -webkit-text-stroke: 1px black;
  color: white;
  text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
}
.form-control {
  line-height: 1.7;
  font-size: 16px;
  height: auto;
  margin-bottom: 10px;
  font-weight: normal;
  vertical-align: middle;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
#titulo {
  color: rgb(243, 243, 243);
  text-shadow: 3px 3px 0 #5e5d5d, -1px -1px 0 #6f6f6f, 1px -1px 0 #929292,
    -1px 1px 0 #5c5b5b, 1px 1px 0 #666565;
}

@media (min-width: 200px) and (max-width: 991px) {
  body {
    font-family: "Open Sans", sans-serif;
    text-align: center;
  }
  .form-control .cabeçario-form {
    font-size: 5px;
    padding: 5px 5px;
    line-height: 1;
    margin-left: -6px;
    margin-right: -6px;
  }
  h1 {
    font-size: 30px;
    text-align: center;
  }

  p {
    text-align: center;
  }
  h3 {
    text-align: center;
  }
  span {
    text-align: center;
  }
}
</style>
