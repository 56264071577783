<template>
  <div class="container">
    <br />
    <h1 class="title fw-bold text-center pt-4 pb-4" id="titulo-2">
      Seja Premium
      <h2>Desconto de Lançamento</h2>
    </h1>

    <br />
    <ul class="list-group">
      <span class="list-group-item list-group-item-action active mb-1 h4">
        Aproveite o preço promocional e tenha acesso a benefícios exclusivos:
      </span>
      <span class="list-group-item list-group-item h5">
        Conversor Santander: Converta suas faturas de cartão de crédito de PDF
        para CSV com facilidade</span
      >
      <span class="list-group-item list-group-item h5">
        Conversor para Itaú Pessoa Física*: Esteja preparado para a novidade que
        está chegando!</span
      >
      <span class="list-group-item list-group-item h5">
        Categorias Personalizadas: Cadastre e gerencie suas categorias para
        organizar suas transações de maneira mais eficiente.</span
      >
      <span class="list-group-item list-group-item h5"
        >Escolha o formato do arquivo de saída: Exporte não só em CSV, mas
        também em OFX.</span
      >
      <li class="list-group-item list-group-item-action active mb-1 h4">
        Adquira agora o plano Premium por apenas R$ 89,90/ano!
      </li>
      <li class="list-group-item list-group-item h5">
        Pagamento único no cartão de crédito.
        <a target="_blank" href="https://buy.stripe.com/8wM15x84j4Pm1t66or"
          >Assinar Agora</a
        >
      </li>
      <li class="list-group-item list-group-item h5">
        * Recurso Itau em desenvolvimento. Será disponibilizado em breve.
      </li>
      <li class="list-group-item list-group-item-action active mb-1 h4">
        Garanta já o seu preço promocional!
      </li>
      <li class="list-group-item list-group-item h5">
        Quando o conversor de Itaú Pessoa Física for lançado, esse valor não
        será mais o mesmo. Não perca essa oportunidade!
      </li>
      <li class="list-group-item list-group-item-action active mb-1 h4">
        Garantia de Satisfação:
      </li>
      <li class="list-group-item list-group-item h5">
        Se não ficar satisfeito, devolvemos seu dinheiro. Basta nos contactar no
        WhatsApp para solicitar a devolução.
      </li>
    </ul>
    <br />
    <br />
    <div class="fs-5 pt-4 mb-4">
      <div class="d-grid gap-2">
        <a
          id="btn-converter"
          class="btn btn-outline-light border-dark fs-4 fw-bold fs-6 text-light bg-dark"
          href="https://buy.stripe.com/8wM15x84j4Pm1t66or"
          target="_blank"
        >
          Assinar Agora
        </a>
      </div>
    </div>
    <br />

    <br />
  </div>
</template>
<script>
export default {};
</script>
<style></style>
